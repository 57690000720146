import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { IconButton, SvgIcon } from '@material-ui/core'
import DashboardIcon from '@material-ui/icons/Dashboard'
import PersonIcon from '@material-ui/icons/Person'
import PublicIcon from '@material-ui/icons/Public'
import PhoneIcon from '@material-ui/icons/Phone'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import ClearIcon from '@material-ui/icons/Clear'
import LoopIcon from '@material-ui/icons/Loop'
import InfoIcon from '@material-ui/icons/Info'
import ShareIcon from '@material-ui/icons/Share'
import NotificationsIcon from '@material-ui/icons/Notifications'
import RocketPath from '../../../Images/Icons/rocket'
import WalletPath from '../../../Images/Icons/wallet'
import VCardPath from '../../../Images/Icons/v-card'
import TextDocumentPath from '../../../Images/Icons/text-document'
import { Metrics } from '../../../Themes'
import styles from '../../Styles/UI/Button'
import menuStyles from '../../Styles/UI/Menu'

export default class SimpleIconButton extends Component {

  static propTypes = {
    active: PropTypes.bool.isRequired, 
    onPress: PropTypes.func.isRequired,
    icon: PropTypes.oneOf(
      [
        'dashboard', 'person', 'rocket', 'wallet', 'world', 'identification',
        'phone', 'terms', 'logout', 'clear', 'refresh', 'info', 'share', 'notifications'
      ]
    ).isRequired,
    firstItem: PropTypes.bool,
    lastItem: PropTypes.bool,
    buttonStyle: PropTypes.object,
    customButtonStyle: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array
    ]),
    customIconStyle: PropTypes.object
  }

  static defaultProps = {
    active: false,
    onPress: null,
    icon: null,
    firstItem: false,
    lastItem: false,
    buttonStyle: { marginTop: 0, marginBottom: Metrics.baseMargin },
    customButtonStyle: {},
    customIconStyle: {}
  }

  render = () => {
    const buttonStyle = Object.assign(
      {}, 
      this.props.buttonStyle, 
      this.props.firstItem ? menuStyles.firstItem : {},
      this.props.lastItem ? menuStyles.lastItem : {},
      this.props.customButtonStyle
    )
    const iconStyle = Object.assign({}, styles.buttonIcon, { marginRight: 0 }, this.props.customIconStyle)
    const svgIconStyle = Object.assign(
      {}, 
      iconStyle, 
      { position: 'relative', top: Metrics.svgIconPositionTop, left: Metrics.svgIconPositionLeft }
    )
    return (
      <IconButton
        onClick={this.props.onPress}
        disabled={false}
        style={buttonStyle}
        color={this.props.active ? 'primary' : 'default'}
      >
        { this.props.icon === 'dashboard' && <DashboardIcon style={iconStyle} /> }
        { this.props.icon === 'person' && <PersonIcon style={iconStyle} /> }
        { this.props.icon === 'rocket' && <SvgIcon style={svgIconStyle}>{RocketPath}</SvgIcon> }
        { this.props.icon === 'wallet' && <SvgIcon style={svgIconStyle}>{WalletPath}</SvgIcon> }
        { this.props.icon === 'world' && <PublicIcon style={iconStyle} /> }
        { this.props.icon === 'identification' && <SvgIcon style={svgIconStyle}>{VCardPath}</SvgIcon> }
        { this.props.icon === 'phone' && <PhoneIcon style={iconStyle} /> }
        { this.props.icon === 'terms' && <SvgIcon style={svgIconStyle}>{TextDocumentPath}</SvgIcon> }
        { this.props.icon === 'logout' && <ExitToAppIcon style={iconStyle} /> }
        { this.props.icon === 'clear' && <ClearIcon style={iconStyle} /> }
        { this.props.icon === 'refresh' && <LoopIcon style={iconStyle} /> }
        { this.props.icon === 'info' && <InfoIcon style={iconStyle} /> }
        { this.props.icon === 'share' && <ShareIcon style={iconStyle} /> }
        { this.props.icon === 'notifications' && <NotificationsIcon style={iconStyle} /> }
      </IconButton>
    )
  }

}
