import { takeLatest, all } from 'redux-saga/effects'

/* ------------- APIs ------------- */

import AuthAPI from '../Services/Auth'
import RaixerAPI from '../Services/Raixer'
// import SoftapAPI from '../Services/Softap'
import StripeAPI from '../Services/Stripe'
// import WifiManagerApi from '../Services/WifiManager'

/* ------------- Types ------------- */

import { StartupTypes } from '../Redux/StartupRedux'
import { AuthTypes } from '../Redux/AuthRedux'
import { ConnectionsTypes } from '../Redux/ConnectionsRedux'
// import { UserTypes } from '../Redux/UserRedux'
import { StripeTypes } from '../Redux/StripeRedux'
// import { WizardTypes } from '../Redux/WizardRedux'
// import { MovementsTypes } from '../Redux/MovementsRedux'
// import { PlatformIntegrationsTypes } from '../Redux/PlatformIntegrationsRedux'
// import { GuestyTypes } from '../Redux/GuestyRedux'
// import { ConfigurationTypes } from '../Redux/ConfigurationRedux'

/* ------------- Sagas ------------- */

import { startup } from './StartupSagas'
import { login, logout, register, facebookLogin, recover, appleLoginCallback } from './AuthSagas'
import { 
  getConnections, 
  postDeviceTask, 
  postNukiTask,
  // postCalibrateServoTask,
  getConnection, 
  getConnectionHistory,
  clearConnectionHistory,
  getConnectionVitals,
  unblockDeviceBehaviours,
  updateDeviceFirmware,
  saveConnectionSchedule,
  deleteConnectionSchedule,
  saveConnectionUser,
  deleteConnectionUser,
  // enableConnectionUser,
  // disableConnectionUser,
  getConnectionQrs,
  saveConnectionAuthorizedPhone,
  deleteConnectionAuthorizedPhone,
  saveConnectionDoorPhoneStatus,
  getDoorPhoneInformation,
  getDoorPhoneCountries,
  deleteDoorPhone,
  saveConnectionDoorPhoneAnyCallerStatus,
  claimPhoneOwnership,
  saveConnectionAutoAccessConfiguration,
  saveConnectionAuthorizedCode,
  deleteConnectionAuthorizedCode,
  saveConnectionDoorCodeStatus,
  getDoorData,
  deleteDoorCode,
  saveConnectionDoorCodeAnyCallerStatus,
  // saveConnectionDeviceConfigurationWizard,
  // deleteConnection,
  // saveConnectionGeneralForm,
  // updateDeviceLedBrightness,
  // updateDeviceEnergySaveMode,
  // getDeviceVariableForm,
  // saveConnectionSensor,
  // deleteConnectionSensor,
  // saveConnectionDoor,
  // deleteConnectionDoor,
  // startAutoCalibration,
  saveLocation,
  deleteLocation,
  // pairLocation,
  getGuestBook,
  sendGuestBook,
  saveBooking,
  deleteBooking,
  getBookings,
  executeInternetTask,
  executeInternetAndBleTask,
} from './ConnectionsSagas'
import { 
  getCustomer,
  addCreditCard,
  editCreditCard,
  deleteCreditCard,
  addSubscription,
  updateSubscription,
  deleteSubscription,
  addPhoneSubscription,
  updatePhoneSubscription,
  deletePhoneSubscription,
  // addGuestySubscription,
  // updateGuestySubscription,
  // deleteGuestySubscription,
  addCheckinSubscription,
  updateCheckinSubscription,
  deleteCheckinSubscription,
  retryPayment
} from './StripeSagas'
import { updateConnectionsOnStorage } from './StorageSagas'

/* ------------- API ------------- */

const authApi = AuthAPI.create()
const raixerApi = RaixerAPI.create()
// const softapApi = SoftapAPI.create()
const stripeApi = StripeAPI.create()
// const wifiManagerApi = WifiManagerApi.create()

/* ------------- Connect Types To Sagas ------------- */

export default function * root () {
  yield all([
    takeLatest(StartupTypes.STARTUP, startup, authApi, raixerApi),
    takeLatest(AuthTypes.LOGIN_REQUEST, login, raixerApi),
    takeLatest(AuthTypes.LOGOUT_REQUEST, logout, raixerApi),
    takeLatest(AuthTypes.REGISTER_REQUEST, register, raixerApi),
    takeLatest(AuthTypes.FACEBOOK_REQUEST, facebookLogin, raixerApi),
    takeLatest(AuthTypes.RECOVER_REQUEST, recover, raixerApi),
    takeLatest(AuthTypes.APPLE_LOGIN_CALLBACK_REQUEST, appleLoginCallback, raixerApi),
    takeLatest(ConnectionsTypes.CONNECTIONS_REQUEST, getConnections, raixerApi),
    takeLatest(ConnectionsTypes.CONNECTIONS_TASK_REQUEST, postDeviceTask, raixerApi),
    takeLatest(ConnectionsTypes.CONNECTION_NUKI_TASK_REQUEST, postNukiTask, raixerApi),
    // TODO:
    takeLatest(ConnectionsTypes.CONNECTION_INTERNET_TASK_REQUEST, executeInternetTask, raixerApi, raixerApi),
    takeLatest(ConnectionsTypes.CONNECTION_INTERNET_AND_BLE_TASK_REQUEST, executeInternetAndBleTask, raixerApi, raixerApi),
    // takeLatest(ConnectionsTypes.CONNECTION_SERVO_CALIBRATE_REQUEST, postCalibrateServoTask, raixerApi),
    takeLatest(ConnectionsTypes.CONNECTION_REQUEST, getConnection, raixerApi),
    takeLatest(ConnectionsTypes.CONNECTION_HISTORY_REQUEST, getConnectionHistory, raixerApi),
    takeLatest(ConnectionsTypes.CONNECTION_HISTORY_CLEAR_REQUEST, clearConnectionHistory, raixerApi),
    takeLatest(ConnectionsTypes.CONNECTION_VITALS_REQUEST, getConnectionVitals, raixerApi),
    takeLatest(ConnectionsTypes.CONNECTION_UNBLOCK_BEHAVIOURS_REQUEST, unblockDeviceBehaviours, raixerApi),
    takeLatest(ConnectionsTypes.CONNECTION_DEVICE_UPDATE_FIRMWARE_REQUEST, updateDeviceFirmware, raixerApi),
    takeLatest(ConnectionsTypes.CONNECTION_SCHEDULE_SAVE_REQUEST, saveConnectionSchedule, raixerApi),
    takeLatest(ConnectionsTypes.CONNECTION_SCHEDULE_DELETE_REQUEST, deleteConnectionSchedule, raixerApi),
    takeLatest(ConnectionsTypes.CONNECTION_USER_SAVE_REQUEST, saveConnectionUser, raixerApi),
    takeLatest(ConnectionsTypes.CONNECTION_USER_DELETE_REQUEST, deleteConnectionUser, raixerApi),
    // takeLatest(ConnectionsTypes.CONNECTION_USER_ENABLE_REQUEST, enableConnectionUser, raixerApi),
    // takeLatest(ConnectionsTypes.CONNECTION_USER_DISABLE_REQUEST, disableConnectionUser, raixerApi),
    takeLatest(ConnectionsTypes.CONNECTION_QRS_REQUEST, getConnectionQrs, raixerApi),
    takeLatest(ConnectionsTypes.CONNECTION_AUTHORIZED_PHONE_SAVE_REQUEST, saveConnectionAuthorizedPhone, raixerApi),
    takeLatest(ConnectionsTypes.CONNECTION_AUTHORIZED_PHONE_DELETE_REQUEST, deleteConnectionAuthorizedPhone, raixerApi),
    takeLatest(ConnectionsTypes.CONNECTION_DOOR_PHONE_STATUS_REQUEST, saveConnectionDoorPhoneStatus, raixerApi),
    takeLatest(ConnectionsTypes.CONNECTION_DOOR_PHONE_INFORMATION_REQUEST, getDoorPhoneInformation, raixerApi),
    takeLatest(ConnectionsTypes.CONNECTION_DOOR_PHONE_COUNTRIES_REQUEST, getDoorPhoneCountries, raixerApi),
    takeLatest(ConnectionsTypes.CONNECTION_DOOR_PHONE_REQUEST, getDoorData, raixerApi, 'PHONE'),
    takeLatest(ConnectionsTypes.CONNECTION_DOOR_PHONE_DELETE_REQUEST, deleteDoorPhone, raixerApi),
    takeLatest(ConnectionsTypes.CONNECTION_DOOR_PHONE_ANY_CALLER_STATUS_REQUEST, saveConnectionDoorPhoneAnyCallerStatus, raixerApi),
    takeLatest(ConnectionsTypes.CONNECTION_DOOR_PHONE_CLAIM_OWNERSHIP_REQUEST, claimPhoneOwnership, raixerApi),
    takeLatest(ConnectionsTypes.CONNECTION_AUTO_ACCESS_CONFIGURATION_SAVE_REQUEST, saveConnectionAutoAccessConfiguration, raixerApi),
    takeLatest(ConnectionsTypes.CONNECTION_AUTHORIZED_CODE_SAVE_REQUEST, saveConnectionAuthorizedCode, raixerApi),
    takeLatest(ConnectionsTypes.CONNECTION_AUTHORIZED_CODE_DELETE_REQUEST, deleteConnectionAuthorizedCode, raixerApi),
    takeLatest(ConnectionsTypes.CONNECTION_DOOR_CODE_STATUS_REQUEST, saveConnectionDoorCodeStatus, raixerApi),
    takeLatest(ConnectionsTypes.CONNECTION_DOOR_CODE_REQUEST, getDoorData, raixerApi, 'CODE'),
    takeLatest(ConnectionsTypes.CONNECTION_DOOR_CODE_DELETE_REQUEST, deleteDoorCode, raixerApi),
    takeLatest(ConnectionsTypes.CONNECTION_DOOR_CODE_ANY_CALLER_STATUS_REQUEST, saveConnectionDoorCodeAnyCallerStatus, raixerApi),
    // takeLatest(ConnectionsTypes.CONNECTION_DEVICE_CONFIGURATION_WIZARD_SAVE_REQUEST, saveConnectionDeviceConfigurationWizard, raixerApi),
    // takeLatest(ConnectionsTypes.CONNECTION_DELETE_REQUEST, deleteConnection, raixerApi),
    // takeLatest(ConnectionsTypes.CONNECTION_GENERAL_FORM_SAVE_REQUEST, saveConnectionGeneralForm, raixerApi), 
    // takeLatest(ConnectionsTypes.CONNECTION_DEVICE_UPDATE_LED_BRIGHTNESS_REQUEST, updateDeviceLedBrightness, raixerApi),
    // takeLatest(ConnectionsTypes.CONNECTION_DEVICE_UPDATE_ENERGY_SAVE_MODE_REQUEST, updateDeviceEnergySaveMode, raixerApi),
    // takeLatest(ConnectionsTypes.CONNECTION_DEVICE_VARIABLE_FORM_REQUEST, getDeviceVariableForm, raixerApi),
    // takeLatest(ConnectionsTypes.CONNECTION_SENSOR_SAVE_REQUEST, saveConnectionSensor, raixerApi),
    // takeLatest(ConnectionsTypes.CONNECTION_SENSOR_DELETE_REQUEST, deleteConnectionSensor, raixerApi),
    // takeLatest(ConnectionsTypes.CONNECTION_DOOR_SAVE_REQUEST, saveConnectionDoor, raixerApi),
    // takeLatest(ConnectionsTypes.CONNECTION_DOOR_DELETE_REQUEST, deleteConnectionDoor, raixerApi),
    // takeLatest(ConnectionsTypes.CONNECTION_AUTO_CALIBRATION_REQUEST, startAutoCalibration, raixerApi),
    takeLatest(ConnectionsTypes.CONNECTION_SAVE_LOCATION_REQUEST, saveLocation, raixerApi),
    takeLatest(ConnectionsTypes.CONNECTION_DELETE_LOCATION_REQUEST, deleteLocation, raixerApi),
    takeLatest(ConnectionsTypes.CONNECTION_SAVE_BOOKING_REQUEST, saveBooking, raixerApi),
    takeLatest(ConnectionsTypes.CONNECTION_DELETE_BOOKING_REQUEST, deleteBooking, raixerApi),
    takeLatest(ConnectionsTypes.CONNECTION_GET_GUEST_BOOK, getGuestBook, raixerApi),
    takeLatest(ConnectionsTypes.CONNECTION_SEND_GUEST_BOOK, sendGuestBook, raixerApi),
    takeLatest(ConnectionsTypes.CONNECTION_GET_BOOKINGS_REQUEST, getBookings, raixerApi),
    // takeLatest(ConnectionsTypes.CONNECTION_PAIR_LOCATION_REQUEST, pairLocation, raixerApi),
    takeLatest(ConnectionsTypes.CONNECTIONS_SUCCESS, updateConnectionsOnStorage),
    // takeLatest(ConnectionsTypes.CONNECTION_SUCCESS, updateConnectionsOnStorage),
    // takeLatest(WizardTypes.ADD_CONNECTION_BY_QR_REQUEST, addConnectionByQr, raixerApi),
    // takeLatest(WizardTypes.ADD_DEVICE_BY_QR_REQUEST, addDeviceByQr, raixerApi),
    // takeLatest(WizardTypes.CLAIM_CODE_WIFI_REQUEST, getClaimCodeWifi, raixerApi, wifiManagerApi),
    // takeLatest(WizardTypes.PHONE_WIFI_REQUEST, getPhoneWifi, wifiManagerApi),
    // takeLatest(WizardTypes.DEVICE_INFO_WIFI_NETWORKS_REQUEST, getDeviceInfoWifiNetworks, wifiManagerApi, softapApi),
    // takeLatest(WizardTypes.WIFI_NETWORKS_REQUEST, getWifiNetworks, softapApi),
    // takeLatest(WizardTypes.WIFI_CONNECT_REQUEST, connectToWifi, softapApi, wifiManagerApi),
    // takeLatest(WizardTypes.ADD_DEVICE_REQUEST, addDevice, raixerApi),
    // takeLatest(WizardTypes.DEVICE_STATUS_REQUEST, getDeviceStatus, raixerApi),
    // takeLatest(WizardTypes.ADD_DEVICE_NUKI_REQUEST, addDeviceNuki, raixerApi),
    // takeLatest(UserTypes.USER_REQUEST, getUser, raixerApi),
    // takeLatest(UserTypes.USER_SAVE_REQUEST, updateUser, raixerApi),
    takeLatest(StripeTypes.CUSTOMER_REQUEST, getCustomer, stripeApi),
    takeLatest(StripeTypes.ADD_CREDIT_CARD_REQUEST, addCreditCard, stripeApi),
    takeLatest(StripeTypes.UPDATE_CREDIT_CARD_REQUEST, editCreditCard, stripeApi),
    takeLatest(StripeTypes.DELETE_CREDIT_CARD_REQUEST, deleteCreditCard, stripeApi),
    takeLatest(StripeTypes.ADD_SUBSCRIPTION_REQUEST, addSubscription, raixerApi),
    takeLatest(StripeTypes.UPDATE_SUBSCRIPTION_REQUEST, updateSubscription, raixerApi),
    takeLatest(StripeTypes.DELETE_SUBSCRIPTION_REQUEST, deleteSubscription, raixerApi),
    takeLatest(StripeTypes.ADD_PHONE_SUBSCRIPTION_REQUEST, addPhoneSubscription, raixerApi),
    takeLatest(StripeTypes.UPDATE_PHONE_SUBSCRIPTION_REQUEST, updatePhoneSubscription, raixerApi),
    takeLatest(StripeTypes.DELETE_PHONE_SUBSCRIPTION_REQUEST, deletePhoneSubscription, raixerApi),
    // takeLatest(StripeTypes.ADD_GUESTY_SUBSCRIPTION_REQUEST, addGuestySubscription, raixerApi),
    // takeLatest(StripeTypes.UPDATE_GUESTY_SUBSCRIPTION_REQUEST, updateGuestySubscription, raixerApi),
    // takeLatest(StripeTypes.DELETE_GUESTY_SUBSCRIPTION_REQUEST, deleteGuestySubscription, raixerApi),
    takeLatest(StripeTypes.ADD_CHECKIN_SUBSCRIPTION_REQUEST, addCheckinSubscription, raixerApi),
    takeLatest(StripeTypes.UPDATE_CHECKIN_SUBSCRIPTION_REQUEST, updateCheckinSubscription, raixerApi),
    takeLatest(StripeTypes.DELETE_CHECKIN_SUBSCRIPTION_REQUEST, deleteCheckinSubscription, raixerApi),
    takeLatest(StripeTypes.RETRY_PAYMENT_REQUEST, retryPayment, raixerApi),
    // takeLatest(MovementsTypes.MOVEMENTS_REQUEST, getMovements, raixerApi),
    // takeLatest(PlatformIntegrationsTypes.REQUEST_INTEGRATION_REQUEST, requestPlatformIntegration, raixerApi),
    // takeLatest(PlatformIntegrationsTypes.REFRESH_INTEGRATION_REQUEST, refreshUserPlatformIntegrations, raixerApi),
    // takeLatest(PlatformIntegrationsTypes.SAVE_REQUEST, savePlatformIntegration, raixerApi),
    // takeLatest(GuestyTypes.LISTINGS_REQUEST, getGuestyListings, raixerApi),
    // takeLatest(GuestyTypes.PAIR_LISTING_REQUEST, pairGuestyListing, raixerApi),
    // takeLatest(GuestyTypes.PAIR_LISTING_DELETE_REQUEST, deleteGuestyListingPair, raixerApi),
    // takeLatest(ConfigurationTypes.CONFIGURATION_REQUEST, getConfiguration, raixerApi)
  ])
}