import React, { Component } from 'react'
import { connect } from 'react-redux'
import RaixerConfig from '../../Config/RaixerConfig'
import I18n from '../../i18n/i18n'
import AuthActions from '../../Redux/AuthRedux'
import LeftMenu from '../../Components/UI/Menu/LeftMenu'
import { FormModalService } from '../../Services/FormModal'
import { ModalService } from '../../Services/Modal'

class LeftMenuScreen extends Component {

  // onPressDashboard = () => {}

  onPressAccount = () => { FormModalService.open('profile') }

  onPressUpgrades = () => { FormModalService.open('upgrades') }

  // onPressCredit = () => {}

  onPressIntegrations = () => { FormModalService.open('integrations') }

  onPressCheckin = () => { FormModalService.open('checkin-upgrade') }

  onPressSupport = () => {
    ModalService.show(
      I18n.t('modals.callSupport.title'),
      I18n.t('modals.callSupport.text'),
      null,
      null,
      I18n.t('modals.buttons.defaultOk')
    )
  }

  onPressTerms = () => { window.open(RaixerConfig.webviews.termsUri, '_blank') }

  onPressLogout = () => { this.props.logout() }

  render = () => {
    return (
      <LeftMenu
        authFetching={this.props.authFetching}
        // onPressDashboard={this.onPressDashboard}
        onPressAccount={this.onPressAccount}
        onPressUpgrades={this.onPressUpgrades}
        // onPressCredit={this.onPressCredit}
        onPressIntegrations={this.onPressIntegrations}
        onPressCheckin={this.onPressCheckin}
        onPressSupport={this.onPressSupport}
        onPressTerms={this.onPressTerms}
        onPressLogout={this.onPressLogout}
      />
    )
  }

}

const mapStateToProps = (state) => {
  return {
    authFetching: state.auth.fetching
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(AuthActions.logoutRequest())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LeftMenuScreen)