import React, { Component } from 'react'
import { connect } from 'react-redux'
import FormModal from '../../Components/UI/Modals/FormModal'
import styles from '../../Components/Styles/UI/Modal'
import { FormModalService } from '../../Services/FormModal'
import { Colors, Metrics } from '../../Themes/'
import AutoAccessFormScreen from '../Connection/Forms/AutoAccessFormScreen'
import ScheduleFormScreen from '../Connection/Forms/ScheduleFormScreen'
import UserFormScreen from '../Connection/Forms/UserFormScreen'
import AuthorizedCodeFormScreen from '../Connection/Forms/AuthorizedCodeFormScreen'
import DoorCodeScreen from '../Connection/Codes/DoorCodeScreen'
import DoorPhoneScreen from '../Connection/Phones/DoorPhoneScreen'
import DoorPhoneChangeConfirmScreen from '../Connection/Phones/DoorPhoneChangeConfirmScreen'
import InformationScreen from '../Connection/Phones/InformationScreen'
import AuthorizedPhoneFormScreen from '../Connection/Forms/AuthorizedPhoneFormScreen'
// import UpgradeScreen from '../Connection/Settings/Forms/UpgradeScreen'
// import ProfileScreen from '../Profile/ProfileScreen'
// import UpgradesScreen from '../Upgrades/UpgradesScreen'
// import CheckinUpgradeScreen from '../Upgrades/CheckinUpgradeScreen'
// import CreditScreen from '../Credit/CreditScreen'
// import IntegrationsScreen from '../Integrations/IntegrationsScreen'
// import PairDeviceFormScreen from '../Connection/Settings/Forms/PairDeviceFormScreen'
// import SetupDeviceWifiScreen from '../Wizard/SetupDeviceWifiScreen'
import OnboardingImageScreen from '../Onboarding/OnboardingImageScreen'
import WorkInProgressScreen from '../WorkInProgress/WorkInProgressScreen'
import LocationFormScreen from '../CheckIn/Forms/LocationFormScreen'
import BookingFormScreen from '../CheckIn/Forms/BookingFormScreen'
import GuestBookScreen from '../CheckIn/Forms/GuestBookScreen'
import GuestFormScreen from '../CheckIn/Forms/GuestFormScreen'
import BookingsHistoryScreen from '../CheckIn/Forms/BookingsHistoryScreen'

class FormModalScreen extends Component {

  constructor(props) {
    super(props)
    this.state = {
      scrollHeight: 0,
      scrollTop: 0,
      clientHeight: 0
    }
  }

  onScroll = (e) => {
    if (this.props.type === 'booking-history') {
      const { target } = e || {}
      const { scrollHeight, scrollTop, clientHeight } = target
      this.setState({ scrollHeight, scrollTop, clientHeight })
    }
  }

  render = () => {
    return (
      <div 
        onClick={(event) => {
          if (event.target === event.currentTarget) {
            FormModalService.close()
          }
        }}
        style={Object.assign(
          {},
          styles.modalContainer,
          { zIndex: Metrics.zIndexes.formModalContainer, backgroundColor: Colors.blackTransparent },
          this.props.visible ? {} : styles.hidden
        )}
      >
        <FormModal>
          <div style={Object.assign({}, styles.modalCard, { width: '80%', maxWidth: '620px', maxHeight: '85%' })} onScroll={this.onScroll}>
            { this.props.type === 'auto-access' && <AutoAccessFormScreen {...this.props.params} /> }
            { this.props.type === 'schedule' && <ScheduleFormScreen {...this.props.params} /> }
            { this.props.type === 'user' && <UserFormScreen {...this.props.params} /> }
            { this.props.type === 'authorized-code' && <AuthorizedCodeFormScreen {...this.props.params} /> }
            { this.props.type === 'door-code' && <DoorCodeScreen {...this.props.params} /> }
            { this.props.type === 'door-phone' && <DoorPhoneScreen {...this.props.params} /> }
            { this.props.type === 'door-phone-change-confirm' && <DoorPhoneChangeConfirmScreen {...this.props.params} /> }
            { this.props.type === 'information' && <InformationScreen {...this.props.params} /> }
            { this.props.type === 'authorized-phone' && <AuthorizedPhoneFormScreen {...this.props.params} /> }
            { this.props.type === 'upgrade' && <WorkInProgressScreen {...this.props.params} /> }
            { this.props.type === 'onboarding-image' && <OnboardingImageScreen {...this.props.params} /> }
            { this.props.type === 'pair-device' && <WorkInProgressScreen {...this.props.params} /> }
            { this.props.type === 'setup-device-wifi' && <WorkInProgressScreen {...this.props.params} /> }
            { this.props.type === 'profile' && <WorkInProgressScreen {...this.props.params} /> }
            { this.props.type === 'upgrades' && <WorkInProgressScreen {...this.props.params} /> }
            { this.props.type === 'checkin-upgrade' && <WorkInProgressScreen {...this.props.params} /> }
            { this.props.type === 'credit' && <WorkInProgressScreen {...this.props.params} /> }
            { this.props.type === 'integrations' && <WorkInProgressScreen {...this.props.params} /> }
            {/* CHECKIN */}
            { this.props.type === 'location' && <LocationFormScreen {...this.props.params} /> }
            { this.props.type === 'booking' && <BookingFormScreen {...this.props.params} /> }
            { this.props.type === 'guest-book' && <GuestBookScreen {...this.props.params} /> }
            { this.props.type === 'guest' && <GuestFormScreen {...this.props.params} navigationParams={this.props.historyParams} /> }
            { this.props.type === 'booking-history' && <BookingsHistoryScreen {...this.state} {...this.props.params} /> }
          </div>
        </FormModal>
      </div>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    visible: state.modal.formVisible,
    type: state.modal.formType,
    params: state.modal.formParams,
    historyParams: state.modal.formHistoryParams,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormModalScreen)